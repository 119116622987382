<template>
  <TemplateCard v-if="!questionNotExisting" :level="EQuestionnaireLevel.Question"
    :title="questionInputs.translations[$i18n.locale].title" :disabled="!isModified" :pending="requestPending"
    @confirm="save" @change:language="onLanguagesChanged" @delete="openDeleteQuestionDialog" @copy="onCopyQuestion">
    <template #content>
      <div class="flex flex-row justify-end mt-3">
        <LanguageTag class="self-end" :class="{ invisible: !languageIsSelected('en') }">DE</LanguageTag>
      </div>

      <Input v-model="questionInputs.staticTitle" :placeholder="$t('score.question-initialTitle')"
        :errorMessage="errorMessages.staticTitle" :disabled="!isNew" @blur="v$.questionInputs.staticTitle.$touch()" />

      <BaseInputs :texts="questionInputs.translations.de" :errorMessages="errorMessages.translations.de"
        :vuelidate="v$.questionInputs.translations.de" :disabled="!isTemplateEditable"
        @update:title="questionInputs.translations.de.title = $event" @update:description="
          questionInputs.translations.de.description = $event
          " @update:clarification="
    questionInputs.translations.de.clarification = $event
    " />

      <div class="flex mt-4">
        <Select class="flex-1" v-model="questionTypeSelectModel" :items="questionTypeOptions"
          :icon="questionTypeSelectModel?.icon" :placeholder="$t('score.question-questionType')"
          :errorMessage="errorMessages.type" :disabled="!isTemplateEditable"
          @close="closeQuestionTypeSelect(questionInputs.type)" #default="{ item }">
          <div class="flex items-center truncate text-darkGray hover:text-primary">
            <Icon class="w-5 h-5 mr-3" :name="item.icon" />
            <HighlightText highlightClass="font-semibold">{{ item.text }}</HighlightText>
          </div>
        </Select>
        <Multiselect class="flex-1 ml-12" v-model="assetClassSelectModel" :items="assetClasses.options.value"
          :placeholder="$t('input.assetClass')" :disabled="!isTemplateEditable" />

        <Multiselect class="flex-1 ml-12" v-model="scoreTypeSelectModel" :items="scoreTypes"
          :placeholder="$t('score.question-select-score-type')" :disabled="!isTemplateEditable" />
      </div>
      <Checkbox class="inline-flex text-lightBlack ml-4" v-model="questionInputs.relevantForReview"
        :disabled="!isTemplateEditable">{{ $t('score.question-relevantForReview') }}</Checkbox>
      <div v-if="isChoiceQuestion" class="mb-4 mt-2">
        <Checkbox class="inline-flex text-lightBlack ml-4" v-model="questionInputs.taxonomyRelevant"
          :disabled="!isTemplateEditable">{{ $t('score.question-taxonomyRelevant') }}</Checkbox>

        <OptionInputs class="mt-8" :opts="{
          models: questionInputs.options,
          vuelidate: v$.questionInputs.options,
          errorMessages: errorMessages.options,
        }" lang="de" :exclusive="questionInputs.type === EQuestionType.MultipleChoice" @update:text="(val, idx) =>
  (questionInputs.options[idx].translations.de.text = val)
  " :disabled="!isTemplateEditable" @update:score="(val, idx) => (questionInputs.options[idx].score = val)
    " @update:isExclusive="(val, idx) => (questionInputs.options[idx].isExclusive = val)
    " @update:position="moveOption" @delete="deleteOption" />
        <Button v-if="isTemplateEditable" class="pl-4 pr-6 mt-4" type="button" variant="secondary" icon="plus"
          iconClasses="w-5" @click="addOption">{{ $t('score.question-addOption') }}</Button>
        <div class="text-xs text-error pl-2 mt-2" :class="{ invisible: !questionCountError }">{{ questionCountError ||
          '&nbsp;' }}</div>
      </div>

      <VirtualQuestionInputs v-else-if="questionInputs.type === EQuestionType.VirtualQuestion"
        v-model:dependantQuestion="questionInputs.parentQuestionId"
        v-model:isGreenHouseGas="questionInputs.isGreenHouseGas" :type="{
          model: questionInputs.virtualQuestionType,
          errorMessage: errorMessages.virtualQuestionType,
          vuelidate: v$.questionInputs.virtualQuestionType,
        }" :averageVariator="{
  model: questionInputs.averageVariator,
  errorMessage: errorMessages.averageVariator,
  vuelidate: v$.questionInputs.averageVariator,
}" :scores="virtQuestScores" :disabled="!isTemplateEditable" @update:type="questionInputs.virtualQuestionType = $event"
        @update:averageVariator="questionInputs.averageVariator = $event"
        @update:score="(val, idx) => (questionInputs.virtScores[idx] = val)"
        @update:scores="questionInputs.virtScores = $event" />

      <!-- english inputs -->

      <div v-if="languageIsSelected('en')" class="flex flex-col border-t-[3px] border-lightestGray mt-10">
        <LanguageTag class="self-end mt-12">EN</LanguageTag>
        <BaseInputs :texts="questionInputs.translations.en" :errorMessages="errorMessages.translations.en"
          :vuelidate="v$.questionInputs.translations.en" :disabled="!isTemplateEditable"
          @update:title="questionInputs.translations.en.title = $event" @update:description="
            questionInputs.translations.en.description = $event
            " @update:clarification="
    questionInputs.translations.en.clarification = $event
    " />
        <OptionInputs v-if="isChoiceQuestion" class="mt-1" :opts="{
          models: questionInputs.options,
          vuelidate: v$.questionInputs.options,
          errorMessages: errorMessages.options,
        }" lang="en" :exclusive="questionInputs.type === EQuestionType.MultipleChoice" @update:text="(val, idx) =>
  (questionInputs.options[idx].translations.en.text = val)
  " :disabled="!isTemplateEditable" @update:score="(val, idx) => (questionInputs.options[idx].score = val)
    " @update:isExclusive="(val, idx) => (questionInputs.options[idx].isExclusive = val)
    " @update:position="moveOption" @delete="deleteOption" />
      </div>

      <DeleteQuestionDialog v-model="deleteQuestionDialog" :title="questionInputs.translations[$i18n.locale].title"
        @delete="onDeleteQuestion" />
    </template>
  </TemplateCard>

  <Card v-else :level="EQuestionnaireLevel.Question">
    <div class="py-6 px-10">
      <h1 class="font-semibold">{{ $t('notifications.questionnaire-question-notFound-warning') }}</h1>
      <p class="whitespace-pre-wrap text-darkGray mt-6" v-html="$t('score.entity-notFound')"></p>
    </div>
  </Card>
</template>

<script>
import { pick, debounce, cloneDeep, orderBy } from 'lodash-es'
import { useStore, mapGetters, mapMutations, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators, useAssetClasses } from '@/composition'
import {
  EClusterType,
  EQuestionType,
  EVirtQuestType,
  EQuestionnaireLevel,
  EScoreTypes,
} from '@/enums'
import { general, question as questionHelpers } from '@/helpers'
import { Input, Select, Multiselect, Button, Checkbox } from '@/components/form'
import Icon from '@/components/Icon'
import HighlightText from '@/components/HighlightText'
import { Card, TemplateCard } from '@/components/questionnaire/cards'
import { LanguageTag } from '@/components/questionnaire'
import {
  BaseInputs,
  OptionInputs,
  VirtualQuestionInputs,
} from '@/components/questionnaire/question'
import { DeleteQuestionDialog } from '@/components/dialog'

const emptyTranslation = {
  title: '',
  description: '',
  clarification: '',
}

const emptyOption = {
  id: null,
  isExclusive: false,
  order: 0,
  score: 0,
  translations: {
    de: {
      text: '',
    },
    en: {
      text: '',
    },
  },
}

export default {
  components: {
    Card,
    TemplateCard,
    Button,
    Icon,
    Input,
    Select,
    Multiselect,
    Checkbox,
    HighlightText,
    DeleteQuestionDialog,
    LanguageTag,
    BaseInputs,
    OptionInputs,
    VirtualQuestionInputs,
  },
  props: {
    clusterNum: {
      type: String,
      required: true,
      validator: val => EClusterType[val] !== undefined,
    },
    categoryId: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const editRequest = useRequest(() => store.dispatch('questionnaire/edit'))
    const assetClasses = useAssetClasses()
    const validators = useValidators()

    return {
      assetClasses: pick(assetClasses, [
        'request',
        'pending',
        'options',
        'toOption',
      ]),
      editRequest,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),

      questionInputs: {
        staticTitle: '',
        type: null, // EQuestionType
        assetTypes: [],
        taxonomyRelevant: false,
        virtScores: [],
        translations: {
          de: Object.assign({}, emptyTranslation),
          en: Object.assign({}, emptyTranslation),
        },
        options: [],
        relevantForReview: false,
        // virtual question fields:
        virtualQuestionType: EVirtQuestType.DK,
        averageVariator: 0,
        parentQuestionId: null,
        isGreenHouseGas: false,
        scoreTypes: []

      },

      questionTypeOptions: this.getQuestionTypeOptions(),
      selectedLanguages: ['de'],
      countOptions: false,
      preventStoreUpdate: true,
      deleteQuestionDialog: false,
      EQuestionnaireLevel,
      EQuestionType,
      questionNotExisting: false,
      scoreTypes: this.getScoreTypesOption(),
    }
  },
  validations() {
    const fieldValidations = {
      type: {
        required: this.validators.required,
      },
      translations: this.selectedLanguages.reduce((res, lang) => {
        res[lang] = {
          title: {
            required: this.validators.required,
          },
          description: {
            required: this.validators.required,
          },
        }
        return res
      }, {}),
    }

    if (this.isNew) {
      fieldValidations.staticTitle = {
        required: this.validators.required,
      }
    }

    if (this.questionInputs.type === EQuestionType.VirtualQuestion) {
      fieldValidations.virtualQuestionType = {
        required: this.validators.required,
      }
      fieldValidations.averageVariator = {
        required: this.validators.required,
        numeric: this.validators.numeric,
      }
      fieldValidations.virtScores = this.questionInputs.virtScores.map(() => ({
        required: this.validators.required,
      }))
    } else if (this.isChoiceQuestion) {
      fieldValidations.options = this.questionInputs.options.map(() => ({
        score: {
          required: this.validators.required,
        },
        translations: this.selectedLanguages.reduce((res, lang) => {
          res[lang] = {
            text: {
              required: this.validators.required,
            },
          }
          return res
        }, {}),
      }))
    }
    return {
      questionInputs: fieldValidations,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'isTemplateEditable',
      'getQuestion',
      'getSection',
      'isNewQuestion',
      'isModified',
    ]),
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categoryIdInt() {
      return parseInt(this.categoryId)
    },
    sectionIdInt() {
      return parseInt(this.sectionId)
    },
    questionIdInt() {
      return parseInt(this.questionId)
    },
    isNew() {
      return this.isNewQuestion(this.questionIdInt)
    },
    errorMessages() {
      const validations = this.v$.questionInputs
      const getError = field => field?.$errors[0]?.$message

      const messages = {
        type: getError(validations.type),
        translations: this.selectedLanguages.reduce((res, lang) => {
          res[lang] = {
            title: getError(validations.translations[lang].title),
            description: getError(validations.translations[lang].description),
          }
          return res
        }, {}),
      }

      if (this.isNew) {
        messages.staticTitle = getError(validations.staticTitle)
      }

      if (this.questionInputs.type === EQuestionType.VirtualQuestion) {
        messages.virtualQuestionType = getError(validations.virtualQuestionType)
        messages.averageVariator = getError(validations.averageVariator)
        messages.virtScores = this.questionInputs.virtScores.map((_, idx) =>
          getError(validations.virtScores[idx])
        )
      } else if (this.isChoiceQuestion) {
        messages.options = this.questionInputs.options.map((_, idx) => ({
          score: getError(validations.options[idx].score),
          translations: this.selectedLanguages.reduce((res, lang) => {
            res[lang] = {
              text: getError(validations.options[idx].translations[lang].text),
            }
            return res
          }, {}),
        }))
      }
      return messages
    },

    questionCountError() {
      if (!this.countOptions) {
        return null
      }
      const minLength = 2
      if (this.questionInputs.options.length < minLength) {
        return this.$t('error.minLengthArray', { min: minLength })
      }
      return null
    },

    assetClassSelectModel: {
      get() {
        return this.questionInputs.assetTypes.map(this.assetClasses.toOption)
      },
      set(selection) {
        this.questionInputs.assetTypes = selection.map(({ model }) => model)
      },
    },

    questionTypeSelectModel: {
      get() {
        return this.questionTypeOptions.find(
          o => o.key === this.questionInputs.type
        )
      },
      set({ key }) {
        this.questionInputs.type = key
      },
    },
    scoreTypeSelectModel: {
      get() {
        return this.scoreTypes.filter(s =>
          this.questionInputs.scoreTypes.includes(s.key)
        )
      },
      set(selection) {
        this.questionInputs.scoreTypes = selection.map(({ key }) => key)
      },
    },

    virtQuestScores() {
      return this.questionInputs.virtScores.map((score, idx) => ({
        model: score,
        errorMessage: this.errorMessages.virtScores[idx],
        vuelidate: this.v$.questionInputs.virtScores[idx],
      }))
    },

    isChoiceQuestion() {
      return (
        this.questionInputs.type === EQuestionType.SingleChoice ||
        this.questionInputs.type === EQuestionType.MultipleChoice
      )
    },

    requestPending() {
      return this.assetClasses.pending.value || this.editRequest.pending.value
    },
  },
  methods: {
    ...mapMutations('questionnaire', ['setQuestion', 'deleteQuestion']),
    ...mapActions('questionnaire', ['copyQuestion']),
    ...mapActions('toastMessage', ['showMessage']),
    getQuestionTypeOptions() {
      return questionHelpers.typeKeys.map(this.questionTypeToOptionsEntry)
    },
    getScoreTypesOption() {
      return questionHelpers.scoreTypes.map(this.scoreTypeToOptionsEntry)
    },
    questionTypeToOptionsEntry(questionTypeKey) {
      return {
        key: questionTypeKey,
        text: questionHelpers.typeName(questionTypeKey),
        icon: questionHelpers.typeIcon(questionTypeKey),
      }
    },
    scoreTypeToOptionsEntry(scoreTypeKey) {
      return {
        key: scoreTypeKey,
        text: this.$t(
          `score.scoretype-${questionHelpers.scoreName(scoreTypeKey)}`
        ),
      }
    },
    closeQuestionTypeSelect() {
      this.v$.questionInputs.type.$touch()
      if (this.isChoiceQuestion) {
        if (this.questionInputs.options.length === 0) {
          this.addOption()
        }
      } else {
        this.countOptions = false
        // virt quest type might have returned undefined from api response
        if (this.questionInputs.virtualQuestionType === undefined) {
          this.questionInputs.virtualQuestionType = EVirtQuestType.DK
        }
      }
    },

    addOption() {
      const lastIdx =
        this.questionInputs.options.push(cloneDeep(emptyOption)) - 1
      this.$nextTick(() => {
        this.v$.questionInputs.options[lastIdx].$reset()
      })
    },
    moveOption(newIdx, oldIdx) {
      this.moveOptionToPosition(
        this.questionInputs.options,
        this.v$.questionInputs.options,
        newIdx,
        oldIdx
      )
    },
    moveOptionToPosition(options, validations, newIdx, oldIdx) {
      const option = options[oldIdx]

      if (oldIdx < newIdx) {
        options.splice(newIdx + 1, 0, option)
        options.splice(oldIdx, 1)
      } else {
        options.splice(newIdx, 0, option)
        options.splice(oldIdx + 1, 1)
      }

      // Set the 'order' property of each option as the index in the array of options
      options.forEach((opt, idx) => {
        opt.order = idx
      })

      // only validate if swapped options are displayed
      if (validations) {
        validations.$touch()
      }
    },
    deleteOption(idx) {
      this.questionInputs.options.splice(idx, 1)
      this.$nextTick(() => {
        this.v$.questionInputs.options.$touch()
      })
    },

    openDeleteQuestionDialog() {
      this.deleteQuestionDialog = true
    },
    onDeleteQuestion() {
      this.deleteQuestion({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
        questionId: this.questionIdInt,
      })
      this.$router.replace({ name: 'editClusterSection' })
      this.showMessage({
        type: 'info',
        translationKey: 'questionnaire-question-delete-info',
      })
    },

    async onCopyQuestion() {
      const newId = await this.copyQuestion({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
        questionId: this.questionIdInt,
      })
      this.$router.replace({ params: { questionId: newId } })
      this.showMessage({
        type: 'info',
        translationKey: 'questionnaire-question-clone-info',
      })
    },

    onLanguagesChanged(langs) {
      if (langs.length > this.selectedLanguages.length) {
        const newLang = langs.find(l => !this.selectedLanguages.includes(l))
        this.$nextTick(() => {
          this.v$.questionInputs.translations[newLang].$reset()
        })
      }
      this.selectedLanguages = langs
    },
    languageIsSelected(lang) {
      return this.selectedLanguages.includes(lang)
    },

    handleNonExistingQuestion() {
      this.questionNotExisting = true
      this.showMessage({
        type: 'warning',
        translationKey: 'questionnaire-question-notFound-warning',
      })
    },

    populateInputs(clusterIdx, categoryIdInt, sectionIdInt, questionIdInt) {
      const question = this.getQuestion({
        clusterIdx,
        categoryId: categoryIdInt,
        sectionId: sectionIdInt,
        questionId: questionIdInt,
      })

      if (!question) {
        return this.handleNonExistingQuestion()
      }

      this.questionNotExisting = false

      const de = question.translations.find(t => t.language === 'de')
      const en = question.translations.find(t => t.language === 'en')

      const opts = question.options.map((option, idx) => ({
        ...option,
        translations: ['de', 'en'].reduce((res, lang) => {
          res[lang] = {
            ...cloneDeep(
              pick(
                question.options[idx].translations.find(
                  t => t.language === lang
                ),
                ['id', 'text']
              )
            ),
          }
          return res
        }, {}),
      }))

      const newInputs = {
        ...cloneDeep(
          pick(question, [
            'staticTitle',
            'type',
            'assetTypes',
            'virtScores',
            'virtualQuestionType',
            'averageVariator',
            'parentQuestionId',
            'isGreenHouseGas',
          ])
        ),
        scoreTypes: question.scoreTypes || [],
        taxonomyRelevant: !!question.taxonomyRelevant, // cast null to false
        translations: {
          de: cloneDeep(general.defaultingPick(de, emptyTranslation)),
          en: cloneDeep(general.defaultingPick(en, emptyTranslation)),
        },
        options: orderBy(opts, 'order'),
        relevantForReview: !!question.relevantForReview // cast null to false
      }

      this.questionInputs = newInputs
      this.$nextTick(() => this.v$.questionInputs.$reset())
    },

    saveToStore() {
      this.debouncedStoreUpdate({
        retrievalConfig: {
          clusterIdx: this.clusterIdx,
          categoryId: this.categoryIdInt,
          sectionId: this.sectionIdInt,
          questionId: this.questionIdInt,
        },
        question: {
          id: this.questionIdInt,
          clusterType: this.clusterIdx,
          ...cloneDeep(
            pick(this.questionInputs, [
              'staticTitle',
              'type',
              'assetTypes',
              'taxonomyRelevant',
              'virtScores',
              'virtualQuestionType',
              'averageVariator',
              'parentQuestionId',
              'isGreenHouseGas',
              'scoreTypes',
              'relevantForReview'
            ])
          ),
          options: orderBy(this.questionInputs.options, 'order').map(
            option => ({
              ...option,
              translations: ['de', 'en'].map(lang => ({
                language: lang,
                ...cloneDeep(pick(option.translations[lang], ['id', 'text'])),
              })),
            })
          ),
          translations: ['de', 'en'].map(lang => ({
            language: lang,
            ...cloneDeep(
              pick(this.questionInputs.translations[lang], [
                'title',
                'description',
                'clarification',
              ])
            ),
            answers: [],
          })),
        },
      })
    },

    async save() {
      this.debouncedStoreUpdate.flush()

      this.v$.questionInputs.$touch()
      if (this.isChoiceQuestion) {
        this.countOptions = true
      }
      if (this.v$.questionInputs.$error || this.questionCountError) {
        return
      }

      const questionOrderBeforeSave = this.getQuestionOrder()

      try {
        await this.editRequest.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-question-edit-success',
        })
        this.goToQuestionFromOrder(questionOrderBeforeSave)
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-question-edit-error',
        })
      }
    },

    getQuestionOrder() {
      const section = this.getSection({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
      })
      return section?.questions?.findIndex(q => q.id === this.questionIdInt)
    },

    goToQuestionFromOrder(order) {
      const section = this.getSection({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
      })
      const question = section?.questions?.[order]
      if (question) {
        this.$router.replace({ params: { questionId: question.id } })
        return
      }
      this.$router.replace({ name: 'editClusterSection' }) // Fallback
    },
  },

  watch: {
    '$route.params.questionId'() {
      if (this.$route.name !== 'editQuestion') {
        return
      }
      this.preventStoreUpdate = true

      this.$nextTick(() => {
        // next tick, so it doesn't happen before route change
        this.populateInputs(
          this.clusterIdx,
          this.categoryIdInt,
          this.sectionIdInt,
          this.questionIdInt
        )
      })
    },
    'questionInputs': {
      handler() {
        // prevent store save when inputs changed due to init or route change
        if (this.preventStoreUpdate) {
          this.preventStoreUpdate = false
        } else {
          this.saveToStore()
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.assetClasses.request()

    // NOTE: we save question to store even if input invalid
    this.debouncedStoreUpdate = debounce(this.setQuestion, 200)

    this.preventStoreUpdate = true

    this.populateInputs(
      this.clusterIdx,
      this.categoryIdInt,
      this.sectionIdInt,
      this.questionIdInt
    )
  },
}
</script>
