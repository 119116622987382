<template>
  <div class="flex flex-col" :class="requestPending && 'cursor-wait'">
    <Select
      class="w-[200px]"
      v-model="questionnaireType"
      :items="questionnareTypeOptions"
      :placeholder="$t('score.questionnaire-type')"
      :disabled="!isTemplateEditable"
    />
    <ScoreTableOverview
      :clusters="clustersTableData"
      :editable="isTemplateEditable"
      @update:weight="updateClusterWeight"
      @update:financeWeight="updateClusterFinanceWeight"
    />

    <div class="bg-white rounded text-lightBlack mt-8 px-11">
      <h2 class="text-lg font-bold py-9">{{ $t('input.assetClass') }}</h2>
      <div class="w-full flex justify-between space-x-4">
        <Select
          v-model="assetClassSelectModel"
          :items="assetClasses.options.value"
          :placeholder="$t('input.assetClass')"
        />
        <Select
          v-model="scoreTypeModel"
          :items="getScoreTypesOption"
          :placeholder="$t('input.scoreType')"
        />
      </div>
    </div>

    <ScoreTableCluster
      v-for="(cluster, clusterIdx) in clustersTableData"
      :key="clusterIdx"
      class="mt-8"
      :number="clusterIdx + 1"
      :editable="isTemplateEditable"
      v-bind="cluster"
      @update:weight="
        (val, catIdx, sectIdx) =>
          updateSectionWeight(val, clusterIdx, catIdx, sectIdx)
      "
      @update:financeWeight="
        (val, catIdx, sectIdx) =>
          updateSectionFinanceWeight(val, clusterIdx, catIdx, sectIdx)
      "
    />

    <Button
      v-if="isTemplateEditable"
      class="self-end px-12 mt-10 mr-6"
      variant="primary"
      :disabled="!isModified || requestPending"
      @click="save"
    >{{ $t('general.save') }}</Button>
  </div>
</template>

<script>
import { pick, sumBy, sum } from 'lodash-es'
import { useStore, mapGetters, mapMutations, mapActions } from 'vuex'
import { general } from '@/helpers'
import { useAssetClasses, useRequest } from '@/composition'
import useVuelidate from '@vuelidate/core'
import { maxValue, between } from '@vuelidate/validators'
import {
  ScoreTableOverview,
  ScoreTableCluster,
} from '@/components/questionnaire/score'
import { Button, Select } from '@/components/form'
import { EQuestionType, EQuestionnaireType } from '@/enums'
import { question as questionHelpers } from '@/helpers'

export default {
  components: {
    ScoreTableOverview,
    ScoreTableCluster,
    Button,
    Select,
  },
  setup() {
    const store = useStore()
    const editRequest = useRequest(() => store.dispatch('questionnaire/edit'))
    const assetClasses = useAssetClasses()

    return {
      editRequest: pick(editRequest, ['request', 'pending']),
      assetClasses: pick(assetClasses, ['request', 'pending', 'options']),
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      assetClassSelectModel: null,
      scoreTypeModel: null,
    }
  },
  validations() {
    return {
      totalPercentage: {
        is100: between(100, 100),
      },
      clustersTableData: this.clustersTableData.map(cluster => ({
        categories: cluster.categories.map(() => ({
          weight: {
            isMax100: maxValue(100),
          },
          financeWeight: {
            isMax100: maxValue(100),
          },
        })),
        totals: {
          weight: {
            is100: between(100, 100),
          },
          financeWeight: {
            is100: between(100, 100),
          },
          weightOverall: {
            isMax100: maxValue(100),
          },
        },
      })),
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'isModified',
      'isTemplateEditable',
      'clusters',
      'type',
    ]),
    requestPending() {
      return this.assetClasses.pending.value || this.editRequest.pending.value
    },
    totalPercentage() {
      return sumBy(this.clustersTableData, 'weight')
    },
    clustersTableData() {
      return this.generateClusterTableData()
    },
    getScoreTypesOption() {
      return [
        {
          key: null,
          text: 'All',
        },
        ...questionHelpers.scoreTypes.map(this.scoreTypeToOptionsEntry),
      ]
    },
    questionnaireType: {
      get() {
        return this.questionnareTypeOptions.find(
          option => option.key == this.type
        )
      },
      set(option) {
        this.setQuestionnaireType(option.key)
      },
    },
    questionnareTypeOptions() {
      const objects = Object.keys(EQuestionnaireType)
        .filter(x => !(parseInt(x) >= 0))
        .map(text => ({ key: EQuestionnaireType[text], text }))
      return objects
    },
  },
  methods: {
    ...mapMutations('questionnaire', [
      'setClusterWeight',
      'setClusterFinanceWeight',
      'setSectionWeight',
      'setSectionFinanceWeight',
      'setQuestionnaireType',
    ]),
    ...mapActions('toastMessage', ['showMessage']),
    generateClusterTableData() {
      const table = this.clusters.map((clst, clusterIdx) => {
        const cluster = {
          ...clst,
        }

        cluster.categories = cluster.categories.map((cat, catIdx) => {
          const category = {
            ...cat,
            identifier: `CLU ${general.numeral(clusterIdx + 1)} ${catIdx + 1}`,
            weight: sumBy(cat.sections, 'weight'),
            sections: cat.sections.map(section => ({
              ...section,
              weightOverall: (cluster.weight / 100) * section.weight,
              financeWeightOverall:
                (cluster.financeWeight / 100) * section.financeWeight,
              maxWeightedScoreOverall: (cluster.weight / 100) * section.weight, // BMC-705: Same as weightOverall
              maxFinanceWeightedScoreOverall:
                (cluster.financeWeight / 100) * section.financeWeight,
            })),
          }
          // Calculate section maxscore, because it may be filtered by asset type or score type
          category.sections.forEach(section => {
            let filteredQuestions
            if (this.scoreTypeModel?.key) {
              filteredQuestions = section.questions?.filter(
                question =>
                  (question.assetTypes.length == 0 ||
                    question.assetTypes.filter(
                      at => at.id === this.assetClassSelectModel?.key
                    ).length > 0) &&
                  (question.scoreTypes.length == 0 ||
                    question.scoreTypes.filter(
                      at => at === this.scoreTypeModel?.key
                    ).length > 0)
              )
            } else {
              filteredQuestions = section.questions?.filter(
                question =>
                  question.assetTypes.length == 0 ||
                  question.assetTypes.filter(
                    at => at.id === this.assetClassSelectModel?.key
                  ).length > 0
              )
            }
            const questionsScores = filteredQuestions?.map(question => {
              let score = 0
              if (question.options) {
                if (question.type === EQuestionType.MultipleChoice) {
                  const scoreForExclusiveOptions = Math.max(
                    ...question.options
                      .filter(option => option.isExclusive)
                      .map(option => option.score)
                  )

                  const scoreForNotExclusiveOptions = sum(
                    question.options
                      .filter(option => !option.isExclusive)
                      .map(option => option.score)
                  )

                  score = Math.max(
                    ...[scoreForExclusiveOptions, scoreForNotExclusiveOptions]
                  )
                } else if (question.type === EQuestionType.VirtualQuestion) {
                  const scores = question.virtScores
                  score = scores.length > 0 ? Math.max(...scores) : 0
                } else {
                  const scores = question.options.map(option => option.score)
                  score = scores.length > 0 ? Math.max(...scores) : 0
                }
              }
              return score
            })
            section.maxScore = sum(questionsScores)
          })
          category.maxScore = sum(category.sections.map(s => s.maxScore))

          category.weightOverall = sumBy(category.sections, 'weightOverall')
          category.financeWeight = sumBy(category.sections, 'financeWeight')
          category.financeWeightOverall = sumBy(
            category.sections,
            'financeWeightOverall'
          )
          category.maxWeightedScoreOverall = sumBy(
            category.sections,
            'maxWeightedScoreOverall'
          )
          category.maxFinanceWeightedScoreOverall = sumBy(
            category.sections,
            'maxFinanceWeightedScoreOverall'
          )
          return category
        })

        cluster.maxWeightedScore = sumBy(
          cluster.categories,
          'maxWeightedScoreOverall'
        )
        cluster.maxScore = sum(cluster.categories.map(cat => cat.maxScore))
        cluster.maxWeightedFinanceScore = sumBy(
          cluster.categories,
          'maxFinanceWeightedScoreOverall'
        )
        return cluster
      })

      table.forEach(cluster => {
        cluster.totals = cluster.categories.reduce(
          (result, cat) => {
            result.weight += cat.weight
            result.weightOverall += cat.weightOverall
            result.maxScore = cluster.maxScore
            result.maxWeightedScoreOverall += cat.maxWeightedScoreOverall
            result.financeWeight += cat.financeWeight
            result.financeWeightOverall += cat.financeWeightOverall
            result.maxFinanceWeightedScoreOverall +=
              cat.maxFinanceWeightedScoreOverall
            return result
          },
          {
            weight: 0,
            weightOverall: 0,
            maxScore: 0,
            maxWeightedScoreOverall: 0,
            financeWeight: 0,
            financeWeightOverall: 0,
            maxFinanceWeightedScoreOverall: 0,
          }
        )
      })

      return table
    },
    updateClusterWeight(weight, clusterIdx) {
      this.setClusterWeight({ weight, clusterIdx })
    },
    updateClusterFinanceWeight(financeWeight, clusterIdx) {
      this.setClusterFinanceWeight({ financeWeight, clusterIdx })
    },
    updateSectionWeight(weight, clusterIdx, categoryIdx, sectionIdx) {
      const cat = this.clusters[clusterIdx].categories[categoryIdx]
      const sect = cat.sections[sectionIdx]

      this.setSectionWeight({
        weight,
        retrievalConfig: {
          clusterIdx,
          categoryId: cat.id,
          sectionId: sect.id,
        },
      })
    },
    updateSectionFinanceWeight(
      financeWeight,
      clusterIdx,
      categoryIdx,
      sectionIdx
    ) {
      const cat = this.clusters[clusterIdx].categories[categoryIdx]
      const sect = cat.sections[sectionIdx]

      this.setSectionFinanceWeight({
        financeWeight,
        retrievalConfig: {
          clusterIdx,
          categoryId: cat.id,
          sectionId: sect.id,
        },
      })
    },
    async save() {
      // we don't check vuelidate here. user might want to save partial quest
      try {
        await this.editRequest.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-edit-error',
        })
      }
    },
    scoreTypeToOptionsEntry(scoreTypeKey) {
      return {
        key: scoreTypeKey,
        text: questionHelpers.scoreName(scoreTypeKey),
      }
    },
  },
  async created() {
    await this.assetClasses.request()
    this.assetClassSelectModel = this.assetClasses.options.value[0]
  },
}
</script>
