<template>
  <TemplateCard
    v-if="!sectionNotExisting"
    :level="EQuestionnaireLevel.Section"
    :title="sectionInputs.title[$i18n.locale]"
    :placeholder="$t('score.section-title')"
    :disabled="!isModified"
    :pending="editRequest.pending.value"
    @change:language="onLanguagesChanged"
    @delete="openDeleteSectionDialog"
    @confirm="save"
  >
    <template #content>
      <div class="flex justify-end mt-3">
        <LanguageTag
          class="self-end"
          :class="{ invisible: !languageIsSelected('en') }"
        >
          DE
        </LanguageTag>
      </div>
      <Input
        v-model="sectionInputs.title.de"
        icon="information"
        iconVariant="end"
        :placeholder="$t('score.section-title')"
        :errorMessage="errorMessages.title.de"
        :disabled="!isTemplateEditable"
        @blur="v$.sectionInputs.title.de.$touch()"
      />
      <Select
        v-model="clusterSelectModel"
        :items="clusterOptions"
        :placeholder="$t('score.cluster')"
        :disabled="true"
      />
      <Select
        v-model="categorySelectModel"
        :items="categoryOptions"
        :placeholder="$t('score.category')"
        :disabled="!isTemplateEditable"
        #default="{ item }"
      >
        <span highlightClass="font-semibold">
          {{ item.text }}
        </span>
      </Select>
      <div
        v-if="languageIsSelected('en')"
        class="flex flex-col border-t-[3px] border-lightestGray mt-10"
      >
        <LanguageTag class="self-end mt-12">EN</LanguageTag>
        <Input
          v-model="sectionInputs.title.en"
          :placeholder="$t('score.section-title')"
          :errorMessage="errorMessages.title.en"
          :disabled="!isTemplateEditable"
          @blur="v$.sectionInputs.title.en.$touch()"
        />
      </div>
      <DeleteSectionDialog
        v-model="deleteSectionDialog"
        :title="sectionInputs.title[$i18n.locale]"
        @delete="onDeleteSection"
      />
    </template>
  </TemplateCard>

  <Card v-else :level="EQuestionnaireLevel.Section">
    <div class="py-6 px-10">
      <h1 class="font-semibold">
        {{ $t('notifications.questionnaire-section-notFound-warning') }}
      </h1>
      <p
        class="whitespace-pre-wrap text-darkGray mt-6"
        v-html="$t('score.entity-notFound')"
      ></p>
    </div>
  </Card>
</template>

<script>
import { debounce } from 'lodash-es'
import { useStore, mapGetters, mapMutations, mapActions } from 'vuex'
import { question as questionHelpers } from '@/helpers'
import { EClusterType, EQuestionnaireLevel } from '@/enums'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { Input, Select } from '@/components/form'
import { Card, TemplateCard } from '@/components/questionnaire/cards'
import { LanguageTag } from '@/components/questionnaire'
import { DeleteSectionDialog } from '@/components/dialog'

export default {
  components: {
    Card,
    TemplateCard,
    Select,
    Input,
    LanguageTag,
    DeleteSectionDialog,
  },
  props: {
    clusterNum: {
      type: String,
      required: true,
      validator: val => EClusterType[val] !== undefined,
    },
    categoryId: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const editRequest = useRequest(() => store.dispatch('questionnaire/edit'))
    const validators = useValidators()

    return {
      editRequest,
      validators,
    }
  },
  data() {
    return {
      sectionInputs: {
        categoryId: null,
        title: {
          de: '',
          en: '',
        },
      },
      v$: useVuelidate(),
      preventStoreUpdate: true,
      deleteSectionDialog: false,
      selectedLanguages: [],
      EQuestionnaireLevel,
      sectionNotExisting: false,
    }
  },
  validations() {
    const fieldValidations = {
      title: {
        de: { required: this.validators.required },
      },
    }
    if (this.languageIsSelected('en')) {
      fieldValidations.title.en = {
        required: this.validators.required,
      }
    }
    return {
      sectionInputs: fieldValidations,
    }
  },

  computed: {
    ...mapGetters('questionnaire', [
      'isTemplateEditable',
      'clusters',
      'getSection',
      'isModified',
    ]),
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categoryIdInt() {
      return parseInt(this.categoryId)
    },
    sectionIdInt() {
      return parseInt(this.sectionId)
    },
    errorMessages() {
      return {
        title: {
          de: this.v$.sectionInputs.title.de.$errors[0]?.$message,
          en: this.v$.sectionInputs.title.en?.$errors[0]?.$message,
        },
      }
    },
    clusterOptions() {
      return [
        {
          key: this.clusterIdx,
          text: this.$t(`score.cluster-name-${this.clusterNum}`),
        },
      ]
    },
    clusterSelectModel() {
      return this.clusterOptions.find(({ key }) => key === this.clusterIdx)
    },
    categoryOptions() {
      return this.clusters[this.clusterIdx].categories.map(cat => ({
        key: cat.id,
        text: questionHelpers.defaultingLangEntry(cat.name).name,
      }))
    },
    categorySelectModel: {
      get() {
        return this.categoryOptions.find(
          ({ key }) => key === this.sectionInputs.categoryId
        )
      },
      set({ key: newCat }) {
        if (newCat !== this.sectionInputs.categoryId) {
          const oldCat = this.sectionInputs.categoryId
          this.sectionInputs.categoryId = newCat
          this.moveToCategory(newCat, oldCat)
        }
      },
    },
  },
  methods: {
    ...mapMutations('questionnaire', [
      'moveSection',
      'deleteSection',
      'setSectionTitle',
    ]),
    ...mapActions('toastMessage', ['showMessage']),
    async save() {
      this.debouncedTitleUpdate.flush()

      this.v$.sectionInputs.$touch()
      if (this.v$.sectionInputs.$error) {
        return
      }
      try {
        await this.editRequest.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-edit-error',
        })
      }
    },

    moveToCategory(toCatId, fromCatId) {
      console.log(toCatId + ' + ' + fromCatId)
      this.moveSection({
        clusterIdx: this.clusterIdx,
        toCatId,
        fromCatId,
        sectionId: this.sectionIdInt,
      })
      this.$router.replace({ params: { categoryId: toCatId } })
    },

    openDeleteSectionDialog() {
      this.deleteSectionDialog = true
    },

    onDeleteSection() {
      this.deleteSection({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
      })
      this.$router.replace({ name: 'editClusterCategory' })
      this.showMessage({
        type: 'info',
        translationKey: 'questionnaire-section-delete-info',
      })
    },

    onLanguagesChanged(langs) {
      this.selectedLanguages = langs
    },

    languageIsSelected(lang) {
      return this.selectedLanguages.includes(lang)
    },

    handleNonExistingSection() {
      this.sectionNotExisting = true
      this.showMessage({
        type: 'warning',
        translationKey: 'questionnaire-section-notFound-warning',
      })
    },

    getNameEntry(langs, locale) {
      return langs.find(entry => entry.language === locale)?.name || ''
    },

    populateInputs(clusterIdx, categoryIdInt, sectionIdInt) {
      const section = this.getSection({
        clusterIdx,
        categoryId: categoryIdInt,
        sectionId: sectionIdInt,
      })

      if (!section) {
        return this.handleNonExistingSection()
      }

      this.sectionNotExisting = false

      this.sectionInputs = {
        categoryId: categoryIdInt,
        title: {
          de: this.getNameEntry(section.name, 'de'),
          en: this.getNameEntry(section.name, 'en'),
        },
      }
      this.$nextTick(() => this.v$.sectionInputs.$reset())
    },
  },
  watch: {
    '$route.params.sectionId'() {
      if (this.$route.name !== 'editClusterSection') {
        return
      }
      this.preventStoreUpdate = true
      this.$nextTick(() => {
        // next tick, so it doesn't happen before route change
        this.populateInputs(
          this.clusterIdx,
          this.categoryIdInt,
          this.sectionIdInt
        )
      })
    },
    'sectionInputs.title': {
      handler(titles) {
        // prevent store save when inputs changed due to init or route change
        if (this.preventStoreUpdate) {
          this.preventStoreUpdate = false
        } else {
          this.debouncedTitleUpdate({
            retrievalConfig: {
              clusterIdx: this.clusterIdx,
              categoryId: this.categoryIdInt,
              sectionId: this.sectionIdInt,
            },
            titles,
          })
        }
      },
      deep: true,
    },
  },
  created() {
    this.debouncedTitleUpdate = debounce((...args) => {
      if (!this.v$.sectionInputs.title.$invalid) {
        this.setSectionTitle(...args)
      }
    }, 200)
    this.preventStoreUpdate = true
    this.populateInputs(this.clusterIdx, this.categoryIdInt, this.sectionIdInt)
  },
}
</script>
