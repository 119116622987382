<template>
  <Input
    :modelValue="texts.title"
    :placeholder="$t('score.question-title')"
    :errorMessage="errorMessages.title"
    :disabled="disabled"
    @update:modelValue="$emit('update:title', $event)"
    @blur="vuelidate.title.$touch()"
  />
  <Textarea
    :modelValue="texts.description"
    :placeholder="$t('score.question-basicQuestion')"
    :errorMessage="errorMessages.description"
    :disabled="disabled"
    @update:modelValue="$emit('update:description', $event)"
    @blur="vuelidate.description.$touch()"
  />
  <Textarea
    :modelValue="texts.clarification"
    :placeholder="$t('score.question-explanation')"
    :errorMessage="errorMessages.clarification"
    :disabled="disabled"
    rows="3"
    @update:modelValue="$emit('update:clarification', $event)"
  />
</template>

<script>
const baseValidation = val =>
  val.title !== undefined && val.description !== undefined

import { Input, Textarea } from '@/components/form'

export default {
  components: {
    Input,
    Textarea,
  },
  props: {
    texts: {
      type: Object,
      required: true,
      validator: val => baseValidation(val) && val.clarification !== undefined,
    },
    errorMessages: {
      type: Object,
      required: true,
    },
    vuelidate: {
      type: Object,
      required: true,
      validator: baseValidation,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
