<template>
  <DynamicView :noPad="true" :breadcrumbs="breadcrumbs">
    <h1 class="pt-[1.25rem] px-[3rem] text-[1.25rem] font-medium text-white">
      {{ $t('pages.score') }}
    </h1>
    <div class="flex mt-6 w-[98vw]">
      <div class="flex flex-col">
        <div class="nav-overflow w-[363px] bg-[#DFE8E1]">
          <NavTreeQuestEdit />
        </div>
      </div>
      <div class="flex-grow flex flex-col bg-backgroundMain p-6">
        <div v-if="isTemplateEditable" class="flex pl-4 pr-8">
          <ButtonWithPopup
            class="self-center"
            type="button"
            variant="secondary"
            sameWidth
            icon="plus"
            iconClasses="w-5"
            :items="navLevels"
            :disabled="pending"
            @select="openAddQuestionnaireItem"
          >
            <template #text>
              {{ $t('general.add') }}
            </template>
            {{ items.text }}
          </ButtonWithPopup>
          <Button
            class="px-4 ml-auto"
            type="submit"
            variant="noBorder"
            icon="release"
            :disabled="isModified"
            @click="openActivateQuestionnaireDialog"
          >
            {{ $t('general.release') }}
          </Button>
          <!-- <Button
            class="px-4 ml-4"
            type="submit"
            variant="noBorder"
            icon="save"
          >
            {{ $t('general.saveAll') }}
          </Button> -->
        </div>
        <ClipLoader
          v-if="pending"
          class="flex flex-center h-60"
          color="green"
          size="4rem"
        />
        <router-view v-else class="mt-4" />
      </div>
    </div>
    <AddQuestionnaireItemDialog
      v-model="addQuestionnaireItemDialog"
      :level="selectedCreateLevel"
      @create="questionnaireItemCreated"
    />
    <LeaveQuestionnaireDialog
      v-model="leaveDialog"
      @confirm="onLeaveConfirmed"
    />
    <ActivateQuestionnaireDialog
      v-model="activateQuestionnaireDialog"
      :title="title"
      @activate="() => {}"
    />
  </DynamicView>
</template>

<script>
import { mapActions } from 'vuex'
import { last } from 'lodash-es'
import { useStore, mapGetters } from 'vuex'
import { useRequest } from '@/composition'
import { EQuestionnaireLevel } from '@/enums'
import { general, question as questionHelpers } from '@/helpers'
import DynamicView from '@/components/layout/DynamicView'
import { Button, ButtonWithPopup } from '@/components/form/'
import {
  AddQuestionnaireItemDialog,
  LeaveQuestionnaireDialog,
  ActivateQuestionnaireDialog,
} from '@/components/dialog'
import { NavTreeQuestEdit } from '@/components/navTree'
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  components: {
    DynamicView,
    Button,
    ButtonWithPopup,
    NavTreeQuestEdit,
    ClipLoader,
    AddQuestionnaireItemDialog,
    LeaveQuestionnaireDialog,
    ActivateQuestionnaireDialog,
  },
  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const request = useRequest(() =>
      store.dispatch('questionnaire/fetch', props.questionnaireId)
    )
    return request
  },
  data() {
    return {
      selectedCreateLevel: EQuestionnaireLevel.Category,
      addQuestionnaireItemDialog: false,
      activateQuestionnaireDialog: false,
      leaveRoute: null,
      leaveDialog: false,
      leaveConfirmed: false,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'title',
      'clusters',
      'isModified',
      'isTemplateEditable',
    ]),
    navLevels() {
      return [
        { key: EQuestionnaireLevel.Category, text: this.$t('score.category') },
        { key: EQuestionnaireLevel.Section, text: this.$t('score.section') },
        { key: EQuestionnaireLevel.Question, text: this.$t('score.question') },
      ]
    },
    breadcrumbs() {
      const result = [
        {
          routeName: 'score',
          text: this.$t('pages.score'),
        },
        {
          routeName: 'editQuestionnaire',
          text: this.$t('pages.editQuestionnaire'),
        },
      ]

      const lastMatch = last(this.$route.matched)

      if (lastMatch.name === 'metadata') {
        result.push({
          text: this.$t('pages.metadata'),
        })
        return result
      }

      result.push({
        routeName: 'editCluster',
        text: `Cluster ${general.numeral(
          parseInt(this.$route.params.clusterNum) + 1
        )}`,
      })

      if (this.pending) {
        return result
      }

      const cluster = this.clusters[this.$route.params.clusterNum]
      let category

      if (
        ['editClusterCategory', 'editClusterSection', 'editQuestion'].includes(
          lastMatch.name
        )
      ) {
        category = cluster.categories.find(
          c => `${c.id}` === this.$route.params.categoryId
        )
        if (!category) {
          return result
        }
        result.push({
          routeName: 'editClusterCategory',
          text: questionHelpers.defaultingLangEntry(category.name).name,
        })
      }

      let section

      if (['editClusterSection', 'editQuestion'].includes(lastMatch.name)) {
        section = category.sections.find(
          s => `${s.id}` === this.$route.params.sectionId
        )
        if (!section) {
          return result
        }
        result.push({
          routeName: 'editClusterSection',
          text: questionHelpers.defaultingLangEntry(section.name).name,
        })
      }

      if (['editQuestion'].includes(lastMatch.name)) {
        const question = section.questions.find(
          s => `${s.id}` === this.$route.params.questionId
        )
        if (!question) {
          return result
        }
        const translation = questionHelpers.defaultingLangEntry(
          question.translations
        )
        result.push({
          routeName: 'editQuestion',
          text: translation.title,
        })
      }

      return result
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    openAddQuestionnaireItem({ key: level }) {
      this.selectedCreateLevel = level
      this.addQuestionnaireItemDialog = true
    },
    questionnaireItemCreated(params) {
      switch (this.selectedCreateLevel) {
        case EQuestionnaireLevel.Category:
          this.$router.push({
            name: 'editClusterCategory',
            params,
          })
          break
        case EQuestionnaireLevel.Section:
          this.$router.push({
            name: 'editClusterSection',
            params,
          })
          break
        case EQuestionnaireLevel.Question:
          this.$router.push({
            name: 'editQuestion',
            params,
          })
      }
    },
    openActivateQuestionnaireDialog() {
      this.activateQuestionnaireDialog = true
    },
    onLeaveConfirmed() {
      this.leaveConfirmed = true
      this.$router.push(this.leaveRoute)
    },
  },
  async created() {
    try {
      await this.request()
    } catch {
      this.showMessage({
        type: 'error',
        translationKey: 'questionnaire-fetch-error',
      })
    }
  },
  beforeRouteLeave(to) {
    if (this.isModified && !this.leaveConfirmed) {
      this.leaveDialog = true
      this.leaveRoute = to
      return false
    }
  },
}
</script>

<style scoped>
.nav-overflow {
  flex-basis: 450px;
  flex-grow: 1;
  overflow-y: scroll;
}
</style>
