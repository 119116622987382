<template>
  <TemplateCard
    v-if="!categoryNotExisting"
    :level="EQuestionnaireLevel.Category"
    :title="categoryInputs.title[$i18n.locale]"
    :placeholder="$t('score.category-title')"
    :disabled="!isModified"
    :pending="editRequest.pending.value"
    @change:language="onLanguagesChanged"
    @delete="openDeleteCategoryDialog"
    @confirm="save"
  >
    <template #content>
      <div class="flex justify-end mt-3">
        <LanguageTag class="self-end" :class="{ invisible: !translationIsSelected('en') }">DE</LanguageTag>
      </div>
      <Input
        v-model="categoryInputs.title.de"
        icon="information"
        iconVariant="end"
        :placeholder="$t('score.category-title')"
        :errorMessage="errorMessages.title.de"
        :disabled="!isTemplateEditable"
        @blur="v$.categoryInputs.title.de.$touch()"
      />
      <Select
        v-model="clusterSelectModel"
        :items="clusterOptions"
        :placeholder="$t('score.cluster')"
        :disabled="!isTemplateEditable"
      />
      <div
        v-if="translationIsSelected('en')"
        class="flex flex-col border-t-[3px] border-lightestGray mt-10"
      >
        <LanguageTag class="self-end mt-12">EN</LanguageTag>
        <Input
          v-model="categoryInputs.title.en"
          :placeholder="$t('score.category-title')"
          :errorMessage="errorMessages.title.en"
          :disabled="!isTemplateEditable"
          @blur="v$.categoryInputs.title.en.$touch()"
        />
      </div>
      <DeleteCategoryDialog
        v-model="deleteCategoryDialog"
        :title="categoryInputs.title[$i18n.locale]"
        @delete="onDeleteCategory"
      />
    </template>
  </TemplateCard>

  <Card v-else :level="EQuestionnaireLevel.Category">
    <div class="py-6 px-10">
      <h1 class="font-semibold">{{ $t('notifications.questionnaire-category-notFound-warning') }}</h1>
      <p class="whitespace-pre-wrap text-darkGray mt-6" v-html="$t('score.entity-notFound')"></p>
    </div>
  </Card>
</template>

<script>
import { debounce } from 'lodash-es'
import { useStore, mapGetters, mapMutations, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { EClusterType, EQuestionnaireLevel } from '@/enums'
import { useRequest, useValidators } from '@/composition'
import { Input, Select } from '@/components/form'
import { LanguageTag } from '@/components/questionnaire'
import { Card, TemplateCard } from '@/components/questionnaire/cards'
import { DeleteCategoryDialog } from '@/components/dialog'

export default {
  components: {
    Card,
    TemplateCard,
    Select,
    Input,
    LanguageTag,
    DeleteCategoryDialog,
  },
  props: {
    clusterNum: {
      type: String,
      required: true,
      validator: val => EClusterType[val] !== undefined,
    },
    categoryId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const editRequest = useRequest(() => store.dispatch('questionnaire/edit'))
    const validators = useValidators()

    return {
      editRequest,
      validators,
    }
  },
  data() {
    return {
      categoryInputs: {
        clusterIdx: null,
        title: {
          de: '',
          en: '',
        },
      },
      v$: useVuelidate(),
      preventStoreUpdate: true,
      deleteCategoryDialog: false,
      selectedLanguages: [],
      EQuestionnaireLevel,
      categoryNotExisting: false,
    }
  },
  validations() {
    const fieldValidations = {
      title: {
        de: { required: this.validators.required },
      },
    }
    if (this.translationIsSelected('en')) {
      fieldValidations.title.en = {
        required: this.validators.required,
      }
    }
    return {
      categoryInputs: fieldValidations,
    }
  },

  computed: {
    ...mapGetters('questionnaire', [
      'isTemplateEditable',
      'getCategory',
      'isModified',
      'getClustersIdentifierArray',
    ]),
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categoryIdInt() {
      return parseInt(this.categoryId)
    },
    errorMessages() {
      return {
        title: {
          de: this.v$.categoryInputs.title.de.$errors[0]?.$message,
          en: this.v$.categoryInputs.title.en?.$errors[0]?.$message,
        },
      }
    },
    clusterOptions() {
      return this.getClustersIdentifierArray.map(clusterIdx => ({
        key: clusterIdx,
        text: this.$t(`score.cluster-name-${clusterIdx}`),
      }))
    },
    clusterSelectModel: {
      get() {
        return this.clusterOptions.find(
          ({ key }) => key === this.categoryInputs.clusterIdx
        )
      },
      set({ key: newCluster }) {
        if (newCluster !== this.categoryInputs.clusterIdx) {
          const oldCluster = this.categoryInputs.clusterIdx
          this.categoryInputs.clusterIdx = newCluster
          this.moveToCluster(newCluster, oldCluster)
        }
      },
    },
  },
  methods: {
    ...mapMutations('questionnaire', [
      'moveCategory',
      'deleteCategory',
      'setCategoryTitle',
    ]),
    ...mapActions('toastMessage', ['showMessage']),
    async save() {
      this.debouncedTitleUpdate.flush()

      this.v$.categoryInputs.$touch()
      if (this.v$.categoryInputs.$error) {
        return
      }
      try {
        await this.editRequest.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-edit-error',
        })
      }
    },

    moveToCluster(toClusterIdx, fromClusterIdx) {
      this.moveCategory({
        toClusterIdx,
        fromClusterIdx,
        categoryId: this.categoryIdInt,
      })
      this.$router.replace({ params: { clusterNum: toClusterIdx } })
    },

    openDeleteCategoryDialog() {
      this.deleteCategoryDialog = true
    },

    onDeleteCategory() {
      this.deleteCategory({
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
      })
      this.$router.replace({ name: 'editCluster' })
      this.showMessage({
        type: 'info',
        translationKey: 'questionnaire-category-delete-info',
      })
    },

    onLanguagesChanged(langs) {
      this.selectedLanguages = langs
    },

    translationIsSelected(lang) {
      return this.selectedLanguages.includes(lang)
    },

    handleNonExistingCategory() {
      this.categoryNotExisting = true
      this.showMessage({
        type: 'warning',
        translationKey: 'questionnaire-category-notFound-warning',
      })
    },

    getNameEntry(langs, locale) {
      return langs.find(entry => entry.language === locale)?.name || ''
    },

    populateInputs(clusterIdx, categoryIdInt) {
      const category = this.getCategory({
        clusterIdx,
        categoryId: categoryIdInt,
      })
      if (!category) {
        return this.handleNonExistingCategory()
      }

      this.categoryNotExisting = false

      this.categoryInputs = {
        clusterIdx,
        title: {
          de: this.getNameEntry(category.name, 'de'),
          en: this.getNameEntry(category.name, 'en'),
        },
      }
      this.$nextTick(() => this.v$.categoryInputs.$reset())
    },
  },
  watch: {
    '$route.params.categoryId'() {
      if (this.$route.name !== 'editClusterCategory') {
        return
      }
      this.preventStoreUpdate = true

      this.$nextTick(() => {
        // next tick, so it doesn't happen before route change
        this.populateInputs(this.clusterIdx, this.categoryIdInt)
      })
    },
    'categoryInputs.title': {
      handler(titles) {
        // prevent store save when inputs changed due to init or route change
        if (this.preventStoreUpdate) {
          this.preventStoreUpdate = false
        } else {
          this.debouncedTitleUpdate({
            retrievalConfig: {
              clusterIdx: this.clusterIdx,
              categoryId: this.categoryIdInt,
            },
            titles,
          })
        }
      },
      deep: true,
    },
  },
  created() {
    this.debouncedTitleUpdate = debounce((...args) => {
      if (!this.v$.categoryInputs.title.$invalid) {
        this.setCategoryTitle(...args)
      }
    }, 200)
    this.preventStoreUpdate = true
    this.populateInputs(this.clusterIdx, this.categoryIdInt)
  },
}
</script>
