<template>
  <div :class="pending && 'cursor-wait'">
    <div class="flex">
      <Select
        class="flex-1"
        v-model="typeSelectModel"
        :items="typeOptions"
        :placeholder="$t('score.question-virtualQuestionType')"
        :errorMessage="type.errorMessage"
        :disabled="disabled"
        @close="type.vuelidate.$touch()"
        #default="{ item }"
      >
        <div
          class="flex items-center truncate text-darkGray hover:text-primary"
        >
          <HighlightText highlightClass="font-semibold">
            {{ item.text }}
          </HighlightText>
        </div>
      </Select>
      <Select
        class="flex-1 ml-12"
        v-model="dependantQuestionSelectModel"
        :items="dependantQuestionOptions"
        :placeholder="$t('score.question-correspondingQuestion')"
        :disabled="disabled"
      />
    </div>

    <div class="flex">
      <FloatInput
        class="flex-1"
        :modelValue="averageVariator.model"
        :placeholder="$t('score.question-averageValue')"
        :errorMessage="averageVariator.errorMessage"
        :disabled="disabled"
        @update:modelValue="$emit('update:averageVariator', $event)"
        @blur="averageVariator.vuelidate.$touch()"
      />
      <div class="flex-1 flex align-middle ml-12">
        <Checkbox
          v-if="showGreenhouseGasCheckbox"
          class="text-darkerGray"
          :modelValue="isGreenHouseGas"
          :disabled="disabled"
          @update:modelValue="$emit('update:isGreenHouseGas', $event)"
        >
          {{ $t('score.question-isGreenhouseGas') }}
        </Checkbox>
      </div>
    </div>

    <ol class="mt-8">
      <li
        v-for="({ model, errorMessage, vuelidate }, idx) in scores"
        :key="idx"
        class="flex items-center"
      >
        <Input
          class="flex-1 mr-4"
          :modelValue="optionTexts[idx]"
          :placeholder="$t('score.question-option', { index: idx + 1 })"
          :disabled="true"
          truncate
        />
        <Icon name="arrow-forward" class="text-lightGreen w-6 h-6" />
        <FloatInput
          class="flex-1 ml-4"
          :modelValue="model"
          :placeholder="$t('score.question-optionScore')"
          :errorMessage="errorMessage"
          :disabled="disabled"
          @update:modelValue="$emit('update:score', $event, idx)"
          @blur="vuelidate.$touch()"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import { times, constant, sortBy } from 'lodash-es'
import { mapGetters, mapActions } from 'vuex'
import { EVirtQuestType } from '@/enums'
import { useRequest } from '@/composition'
import { questionnaires as questionnairesApi } from '@/services/api'
import { question as questionHelpers } from '@/helpers'
import { Select, Input, FloatInput, Checkbox } from '@/components/form'
import HighlightText from '@/components/HighlightText'
import Icon from '@/components/Icon'

const validation = val =>
  val.model !== undefined && val.vuelidate && 'errorMessage' in val

export default {
  components: {
    HighlightText,
    Select,
    Input,
    FloatInput,
    Checkbox,
    Icon,
  },
  props: {
    type: {
      type: Object,
      required: true,
      validator: val =>
        validation(val) && EVirtQuestType[val.model] !== undefined,
    },
    dependantQuestion: {
      type: Number,
      default: null,
    },
    averageVariator: {
      type: Object,
      required: true,
      validator: validation,
    },
    isGreenHouseGas: {
      type: Boolean,
      required: true,
    },
    scores: {
      type: Array,
      required: true,
      validator: list => list.every(validation),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:type',
    'update:dependantQuestion',
    'update:averageVariator',
    'update:isGreenHouseGas',
    'update:score',
    'update:scores',
  ],
  setup() {
    return useRequest(questionnairesApi.getVirtQuestScoring)
  },
  data() {
    return {
      typeOptions: [],
      dependantQuestionOptions: [], // populated in created hook
      optionScoringData: [],
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['getClusterVirtQuestions']),
    clusterIdx() {
      return parseInt(this.$route.params.clusterNum)
    },
    categoryId() {
      return parseInt(this.$route.params.categoryId)
    },
    sectionId() {
      return parseInt(this.$route.params.sectionId)
    },
    questionId() {
      return parseInt(this.$route.params.questionId)
    },
    showGreenhouseGasCheckbox() {
      return this.type.model !== EVirtQuestType.DW
    },
    typeSelectModel: {
      get() {
        return this.typeOptions.find(o => o.key === this.type.model)
      },
      set({ key }) {
        this.$emit('update:type', key)
      },
    },
    dependantQuestionSelectModel: {
      get() {
        return this.dependantQuestionOptions.find(
          o => o.key === this.dependantQuestion
        )
      },
      set({ key }) {
        this.$emit('update:dependantQuestion', key)
      },
    },
    optionTexts() {
      const texts = [
        this.$t('score.question-virtualQuestionType-all-noStatement'),
      ]

      if (this.type.model === EVirtQuestType.DW) {
        return texts.concat(
          this.optionScoringData.map(({ limit, description }) => {
            const level = limit < 0 ? 'below' : 'above'
            return this.$t(`score.question-virtualQuestionType-dw-${level}`, {
              value: description,
            })
          })
        )
      }

      if (this.type.model === EVirtQuestType.DK) {
        return texts.concat(
          this.optionScoringData.map(({ description }) =>
            this.$t('score.question-virtualQuestionType-dk-deviation', {
              value: description,
            })
          )
        )
      }

      if (this.type.model === EVirtQuestType.Year) {
        return texts.concat(
          this.optionScoringData.map(
            ({ limit, description }) =>
              `${description} ${this.$tc('general.years', Math.abs(limit))}`
          )
        )
      }

      return texts.concat(
        this.optionScoringData.map(({ description }) => description)
      )
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    setTypeOptions() {
      this.typeOptions = questionHelpers.virtQuestTypeKeys.map(
        this.typeToOptionsEntry
      )
    },
    typeToOptionsEntry(typeKey) {
      return {
        key: typeKey,
        text: questionHelpers.virtQuestTypeName(typeKey),
      }
    },

    getDependantQuestionOptions() {
      const questions = this.getClusterVirtQuestions(this.clusterIdx).filter(
        ({ id }) => id !== this.questionId
      )
      return questions
        .map(({ id, translations }) => {
          const translation = questionHelpers.defaultingLangEntry(translations)
          return {
            key: id,
            text: translation.title,
          }
        })
        .concat({
          key: null,
          text: this.$t('general.none'),
        })
    },

    async setScoreOptions() {
      try {
        this.optionScoringData = sortBy(
          await this.request(this.type.model),
          'limit'
        )
        this.adjustScoreList()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-virtScoringOptions-fetch-error',
        })
      }
    },

    adjustScoreList() {
      const scores = this.scores.map(s => s.model)
      const numOptions = this.optionScoringData.length + 1 // +1: 'Keine Angabe'
      const diff = numOptions - scores.length

      if (diff > 0) {
        // more texts than scores
        this.$emit('update:scores', scores.concat(times(diff, constant(0))))
      } else if (diff < 0) {
        // more scores than texts
        this.$emit('update:scores', scores.slice(0, diff))
      }
    },

    setDependantQuestionOptions() {
      this.dependantQuestionOptions = this.getDependantQuestionOptions()
    },
  },
  watch: {
    '$route.params.questionId': {
      handler(newQuest) {
        if (newQuest) {
          this.setDependantQuestionOptions()
        }
      },
      immediate: true,
    },
    'type': {
      handler(newValue, oldValue) {
        if (newValue?.model !== oldValue?.model) {
          this.setScoreOptions()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setTypeOptions()
  },
}
</script>
