<template>
  <div class="flex flex-col" :class="pending && 'cursor-wait'">
    <div>
      <Select
        class="w-[200px]"
        v-model="clusterIdentifier"
        :items="clusterIdentifierOptions"
        :placeholder="$t('score.cluster-identifier')"
        :disabled="!isTemplateEditable"
      />
      <Checkbox
        class="inline-flex text-lightBlack ml-4"
        v-model="isTaxonomyCluster"
      >{{ $t('score.question-taxonomyRelevant') }}</Checkbox>
    </div>
    <Draggable
      v-if="categories.length > 0"
      tag="ol"
      item-key="id"
      handle=".drag-handle"
      ghost-class="drop-target"
      group="category"
      v-bind="dragOptions"
      v-model="categories"
    >
      <template #item="{ element: category, index: idxCategory }">
        <li>
          <DraggableCard
            :name="catOrSectName(category)"
            :level="EQuestionnaireLevel.Category"
            :disabled="!isTemplateEditable"
          />
          <Draggable
            tag="ol"
            item-key="key"
            handle=".drag-handle"
            ghost-class="drop-target"
            group="section"
            v-bind="dragOptions"
            :modelValue="categories[idxCategory].sections"
            @update:modelValue="updateCategory(category.id, $event)"
          >
            <template #item="{ element: section, index: idxSection }">
              <li>
                <DraggableCard
                  :name="catOrSectName(section)"
                  :level="EQuestionnaireLevel.Section"
                  :disabled="!isTemplateEditable"
                />
                <Draggable
                  tag="ol"
                  item-key="key"
                  handle=".drag-handle"
                  ghost-class="drop-target"
                  group="question"
                  v-bind="dragOptions"
                  :modelValue="
                    categories[idxCategory].sections[idxSection].questions
                  "
                  @update:modelValue="
                    updateSection(category.id, section.id, $event)
                  "
                >
                  <template #item="{ element: question }">
                    <li>
                      <DraggableCard
                        :name="questionName(question)"
                        :level="EQuestionnaireLevel.Question"
                        :disabled="!isTemplateEditable"
                      />
                    </li>
                  </template>
                </Draggable>
              </li>
            </template>
          </Draggable>
        </li>
      </template>
    </Draggable>

    <div v-else class="flex justify-center py-4">{{ $t('score.cluster-empty') }}</div>

    <Button
      v-if="isTemplateEditable"
      class="self-end px-12 mt-10 mr-6"
      type="submit"
      variant="primary"
      :disabled="!isModified || pending"
      @click="save"
    >{{ $t('general.save') }}</Button>
  </div>
</template>

<script>
import { default as Draggable } from 'vuedraggable'
import { useStore, mapGetters, mapMutations, mapActions } from 'vuex'
import { useRequest } from '@/composition'
import { question as questionHelpers } from '@/helpers'
import { EClusterType, EQuestionnaireLevel } from '@/enums'
import { DraggableCard } from '@/components/questionnaire/cards'
import { Button, Select, Checkbox } from '@/components/form'

export default {
  components: {
    Draggable,
    DraggableCard,
    Button,
    Select,
    Checkbox,
  },
  props: {
    clusterNum: {
      type: String,
      required: true,
      validator: val => EClusterType[val] !== undefined,
    },
  },
  setup() {
    const store = useStore()
    const editRequest = useRequest(() => store.dispatch('questionnaire/edit'))
    return editRequest
  },
  data() {
    return {
      dragOptions: {
        // https://stackoverflow.com/questions/58505505/how-to-use-autoscroll-feature-vue-draggable
        scrollSensitivity: 200,
        forceFallback: true,
      },
      EQuestionnaireLevel,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'isModified',
      'isTemplateEditable',
      'clusters',
      'getClustersIdentifierArray',
    ]),
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categories: {
      get() {
        return this.clusters[this.clusterNum].categories
      },
      set(cats) {
        this.setClusterCategories({ clusterIdx: this.clusterIdx, cats })
      },
    },

    clusterIdentifier: {
      get() {
        const clusterIdentifier =
          this.clusters[this.clusterNum].clusterIdentifier
        return {
          key: clusterIdentifier,
          text: this.$t('score.cluster-identifier-with-number', {
            number: clusterIdentifier,
          }),
        }
      },
      set(clusterIdentifierOption) {
        this.setClusterIdentifier({
          clusterIdx: this.clusterIdx,
          clusterIdentifier: clusterIdentifierOption.key,
        })
      },
    },
    isTaxonomyCluster: {
      get() {
        return this.clusters[this.clusterNum].isTaxonomyCluster
      },
      set(isTaxonomyCluster) {
        this.setClusterTaxanomy({
          clusterIdx: this.clusterIdx,
          isTaxonomyCluster,
        })
      },
    },
    clusterIdentifierOptions() {
      return Array.from({ length: this.clusters.length }, (_, i) => i + 1).map(
        clusterIdentifier => ({
          key: clusterIdentifier,
          text: this.$t('score.cluster-identifier-with-number', {
            number: clusterIdentifier,
          }),
        })
      )
    },
  },
  methods: {
    ...mapMutations('questionnaire', [
      'setClusterCategories',
      'setCategorySections',
      'setSectionQuestions',
      'setClusterIdentifier',
      'setClusterTaxanomy',
    ]),
    ...mapActions('questionnaire', ['edit']),
    ...mapActions('toastMessage', ['showMessage']),
    updateCategory(categoryId, sections) {
      this.setCategorySections({
        retrievalConfig: { clusterIdx: this.clusterIdx, categoryId },
        sections,
      })
    },
    updateSection(categoryId, sectionId, questions) {
      this.setSectionQuestions({
        retrievalConfig: { clusterIdx: this.clusterIdx, categoryId, sectionId },
        questions,
      })
    },
    catOrSectName(entry) {
      return questionHelpers.defaultingLangEntry(entry.name).name
    },
    questionName(question) {
      return questionHelpers.defaultingLangEntry(question.translations).title
    },
    async save() {
      try {
        await this.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-edit-error',
        })
      }
    },
  },
}
</script>

<style scoped>
.drop-target {
  @apply opacity-50 bg-primary;
}
</style>
