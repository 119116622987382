<template>
  <Draggable
    tag="ol"
    item-key="key"
    handle=".drag-handle"
    ghost-class="drop-target"
    group="category"
    :modelValue="options"
    v-bind="dragOptions"
    @end="onDragEnd"
  >
    <template #item="{ element: option, index }">
      <div class="border-t-2 border-lighterGray">
        <li class="relative flex items-center -ml-9 pt-4">
          <Icon
            class="
              drag-handle
              text-darkGray
              w-8
              mr-1
              transform
              rotate-90
              translate-y-0.5
              cursor-[grab]
            "
            :class="disabled && 'invisible pointer-events-none'"
            name="drag-handle"
          />
          <Input
            class="flex-1 mr-4"
            :modelValue="option.text.model"
            :placeholder="$t('score.question-option', { index: index + 1 })"
            :errorMessage="option.text.errorMessage"
            :disabled="disabled"
            @update:modelValue="$emit('update:text', $event, index)"
            @blur="option.text.vuelidate.$touch()"
          />
          <Icon name="arrow-forward" class="text-lightGreen w-6 h-6" />
          <FloatInput
            class="flex-1 ml-4"
            :modelValue="option.score.model"
            :placeholder="$t('score.question-optionScore')"
            :errorMessage="option.score.errorMessage"
            :disabled="disabled"
            @update:modelValue="$emit('update:score', $event, index)"
            @blur="option.score.vuelidate.$touch()"
          />
          <Icon
            name="trash"
            class="text-darkGray ml-4 w-6 h-6 cursor-pointer"
            :class="{
              'invisible pointer-events-none': options.length < 2 || disabled,
            }"
            @click="$emit('delete', index)"
          />
        </li>
        <li v-if="exclusive">
          <Checkbox
            class="self-start mb-6"
            :modelValue="option.isExclusive"
            @update:modelValue="$emit('update:isExclusive', $event, index)"
            :disabled="disabled"
          >
            <span class="text-darkGray">
              {{ $t('score.question-exclusiveOption') }}
            </span>
          </Checkbox>
        </li>
      </div>
    </template>
  </Draggable>
</template>

<script>
import { default as Draggable } from 'vuedraggable'
import { Input, FloatInput, Checkbox } from '@/components/form'
import Icon from '@/components/Icon'

const validation = optionParts =>
  optionParts.models && optionParts.vuelidate && optionParts.errorMessages

export default {
  components: {
    Draggable,
    Input,
    FloatInput,
    Icon,
    Checkbox,
  },
  emits: [
    'update:text',
    'update:score',
    'update:position',
    'update:isExclusive',
    'delete',
  ],
  props: {
    opts: {
      type: Object,
      required: true,
      validator: validation,
    },
    lang: {
      type: String,
      required: true,
      validator: val => ['de', 'en'].includes(val),
    },
    exclusive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragOptions: {
        // https://stackoverflow.com/questions/58505505/how-to-use-autoscroll-feature-vue-draggable
        scrollSensitivity: 200,
        forceFallback: true,
      },
    }
  },
  computed: {
    options() {
      return this.opts.models.map((_, idx) => ({
        key: idx,
        text: {
          model: this.opts.models[idx].translations[this.lang].text,
          errorMessage:
            this.opts.errorMessages[idx].translations[this.lang].text,
          vuelidate: this.opts.vuelidate[idx].translations[this.lang].text,
        },
        score: {
          model: this.opts.models[idx].score,
          errorMessage: this.opts.errorMessages[idx].score,
          vuelidate: this.opts.vuelidate[idx].score,
        },
        isExclusive: this.opts.models[idx].isExclusive,
      }))
    },
  },
  methods: {
    onDragEnd({ newIndex, oldIndex }) {
      if (newIndex !== oldIndex) {
        this.$emit('update:position', newIndex, oldIndex)
      }
    },
  },
}
</script>

<style scoped>
.drop-target {
  @apply border border-primary rounded-lg;
}
.drop-target > * {
  @apply opacity-50;
}
</style>
